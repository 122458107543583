import React from 'react'
import { Link } from "gatsby";

import Layout from "../../components/Layout"

import styles from './styles.module.scss';
import SEO from "../../components/seo";

const NotFoundPage = () => (
    <Layout>
      <SEO title="Page not found" />

      <div className={styles.NotFoundPage}>
        <div className={styles.title}>404</div>

        Congrats! You just found a page that we don't have!

        <br />
        <br />
        Now, go back to the <Link to="/" className={styles.link}>homepage</Link> :)
      </div>
    </Layout>
);

export default NotFoundPage
